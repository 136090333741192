@import url(../node_modules/@blueprintjs/core/lib/css/blueprint.css);
@import url(../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css);
html {
  height: 100%;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  overflow: hidden;

  color: #333;
  background-color: #ffffff;
}

#root {
  flex: 1 auto;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#root .bp3-input:disabled,
#root .bp3-input.bp3-disabled {
  color: rgba(30, 30, 30, 0.8);
}

.ApplyCommonBackground {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%23009aff' fill-opacity='0.08'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
}
